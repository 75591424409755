<mat-dialog-content>
	<h2>{{question}}</h2>
  <div *ngIf="infoLine1 != null" style="width: 100%; text-align: center;">
    {{infoLine1}}
  </div>
</mat-dialog-content>
  
<mat-dialog-actions class="fennec-controls">
	<button 
		*ngIf="buttonMode === 'YES/NO'"
		mat-flat-button 
		(click)="onYes()" 
		color="accent">
			<mat-icon>check_circle</mat-icon> Yes
	</button>

	<button 
		*ngIf="buttonMode === 'YES/NO'"
		mat-button 
		mat-stroked-button 
		(click)="onNo()">
			<mat-icon>cancel</mat-icon> No
	</button>

	<button 
		*ngIf="buttonMode === 'OK'"
		mat-flat-button 
		(click)="onYes()" 
		color="accent">
			<mat-icon>check_circle</mat-icon> OK
	</button>
</mat-dialog-actions>
