<span class="container">
  @if (buttonType == "icon") {
    <button
      class="button"
      mat-icon-button
      [color]="totalRowCount > 0 ? 'accent' : ''"
      (click)="openCommentDialog()">
      <mat-icon>comments</mat-icon>
    </button>
  }@else if (buttonType == "menuItem") {
    <button
      style="color:darkorange;"
      class="button"
      mat-menu-item
      color="accent"
      (click)="openCommentDialog()">
      <mat-icon>comments</mat-icon> View Comments
    </button>
  }@else {
    <button
      class="button"
      mat-raised-button
      color="accent"
      (click)="openCommentDialog()">
      <mat-icon>comments</mat-icon> View Comments
    </button>
  }
  <div class="comment-info-div">
    @if (loading) {
      <div class="loading-spinner-div">
        <mat-spinner color="accent"></mat-spinner>
      </div>
    }@else {
      <div>
        <p>
          {{ totalRowCount }} Comment{{ totalRowCount != 1 ? "s" : ""}}
        </p>
    
        @for (comment of commentList; track comment) {
          <p>
            <span>
              {{(comment.createdDate | date: 'MM/dd/yyyy h:mm a') + (comment.createdDate !== comment.lastModifiedDate ? ' (edited: ' + (comment.lastModifiedDate | date: 'M/dd/yyyy h:mm a') + ')' : '')}}
            </span>
    
            <span>
              {{ comment.authorFirstName }} {{ comment.authorLastName }}
            </span>
          </p>
        }
    
        @if (totalRowCount > 3) {
          <span>...{{ totalRowCount - 3 }} more</span>
        }
      </div>
    }
  </div>
</span>