import { Component, HostListener, Input, OnInit } from '@angular/core';
import { 
  BaseComponent, 
  CommentQueryParamsPacket,
  FennecSnackbarService, 
  Logger 
} from "xf-common";
import { CommentService } from '../comment.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { CommentListDialogComponent } from '../comment-list-dialog/comment-list-dialog.component';

@Component({
  selector: 'app-comment-list-dialog-button',
  templateUrl: './comment-list-dialog-button.component.html',
  styleUrl: './comment-list-dialog-button.component.scss'
})
export class CommentListDialogButtonComponent extends BaseComponent implements OnInit {

  protected log: Logger = new Logger("CommentListDialogButtonComponent");

  @Input()
  buttonType:"icon" | "menuItem" | null = null;

  @Input()
  commentMode:string = "-1";

  @Input()
  relatedId:string = "-1";

  // ids For Itemized Line Item Comments
  @Input()
  lineItemIds:[] = [];

  commentList: any[] = [];
  public totalRowCount = 0;

  loading = false;

  loaded = false;
  hoverTimeout:any = null;

  constructor(
    public snack: FennecSnackbarService,
    protected commentService:CommentService,
    public matDialog: MatDialog,
  ) {
    super();
  }

  ngOnInit(): void {
    // this.getComments();
  }

  @HostListener('mouseenter') mouseover(event :Event)
  {
    // console.log("Hello!");
    if (!this.loaded) {
      this.loading = true;
      this.hoverTimeout = setTimeout(this.getComments, 300);
      // this.getComments();
    }
  }

  @HostListener('mouseleave') mouseleave(event: Event)
  {
    if (this.hoverTimeout != null) {
      clearTimeout(this.hoverTimeout);
    }
  }

  openCommentDialog = () => {
    if (this.relatedId === "-1") {
      this.snack.showErrorSnack("Related ID Not provided to Comment List Dialog Button");
    }

    if (this.commentMode === "-1") {
      this.snack.showErrorSnack("Comment Mode Not provided to Comment List Dialog Button");
    }
    const id = parseInt(this.relatedId);

    
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "75vw";
    matDialogConfig.data = {
      mode: this.commentMode,
      relatedId: id
    }
    const dialogRef = this.matDialog.open(CommentListDialogComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      this.getComments();
    })
  }

  getComments = () => {
    
    // let paginator = this.paginator;
    // if(this.dialogPaginator) {
    //   paginator = this.dialogPaginator;
    // }
    
    if(this.relatedId == "-1") {
      super.showErrorSnack("No ID Provided");
      return;
    }
    this.loading = true;
    
    const id = parseInt(this.relatedId);
    let packet = new CommentQueryParamsPacket();
    packet.relatedId = id;
    packet.commentRelationalType = this.commentMode;


    if(this.commentMode === "ITEMIZED_LINE_ITEM") {
      packet.lineItemIds = this.lineItemIds;
    }

    const pageSize = 3;
    const first = 0;

    this.performXFRequest({
      requestDescription: "Get Comments",
      requestFn: this.commentService.getComments,
      fnParams: [packet, first, pageSize],
      // onComplete: () => {
      //   this.isLoading = false;
      // },
      onResponse: response => {
        this.commentList = response.data;
        this.totalRowCount = response['totalRowCount'];
        this.loading = false;
        this.loaded = true;
      },
      onError: (error) => {
        super.showErrorSnack(error);
      }
    })
  }
}
