import {
  AfterContentChecked,
  AfterViewChecked,
  AfterViewInit, ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { XFWFActionDialogComponent } from '../xfwf-action-dialog/xfwf-action-dialog.component';
import { XfwfService } from '../xfwf.service';
import {
  BaseComponent,
  Logger,
  MICaseService,
  PagedResponse,
  SelectedUser,
  SimpleObject,
  UserGroupQueryPacket
} from 'xf-common';
import { KeycloakService } from 'keycloak-angular';
import { SingleChoiceDialogComponent } from 'xf-common';
import { DateUtil } from 'xf-common';
import { FennecSnackbarService } from 'xf-common';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { XfwfCreateManualComponent } from '../xfwf-create-manual/xfwf-create-manual.component';
import { CommentListDialogComponent } from '@app/comments/comment-list-dialog/comment-list-dialog.component';
import {UserGroupService} from "@app/admin/user-group.service";
import { ActivityLogDialogComponent } from '../../activity-log/activity-log-dialog/activity-log-dialog.component';
import { ActivityLogDialogConfig } from 'projects/xf-common/src/public-api';

@Component({
  selector: 'app-xfwf-task-list',
  templateUrl: './xfwf-task-list.component.html',
  styleUrls: ['./xfwf-task-list.component.scss', '../../../styles.scss']
})
export class XFWFTaskListComponent extends BaseComponent implements AfterViewInit, OnInit, OnChanges {
  log: Logger = new Logger("XFWFTaskListComponent");

  @Input()
  miCaseId?: string | null = null;

  @Input()
  view: 'DEFAULT' | 'MICASE' = 'DEFAULT';

  @Input()
  caseOnHold = false;

  // All or OPERATIVE
  @Input()
  mode: 'ALL' | 'OPERATIVE' = 'OPERATIVE';

  @Output()
  editHoldInfo = new EventEmitter<void>()

  displayedColumns: string[] = ['assigned-to', 'mi-case-name', 'status-track-status', 'task-type', 'task-status',
    'last-action-taken', 'total-billed-amount', 'start-date', 'due-date', 'created-date', 'last-modified-date', 'actions', 'comments',
  ];
  taskList: any [] = [];
  formGroup: FormGroup = new FormGroup({});

  @ViewChild(MatPaginator)
  paginator?: MatPaginator;
  totalRowCount?: number;
  defaultPageSize = 20;
  pageSizeOptions = [5, 10, 20, 25, 50];

  taskTypes: any;
  taskStatuses: any;

  // MICaseInfo
  miCaseInfo: any = null;

  // Security related properties
  allowOverride: boolean = false;
  allowUnassignedTaskAssign: boolean = false;
  allowUnassignedTaskRead: boolean = false;
  admin: boolean = false;
  showCreateManualButton: boolean = false;
  initialized: boolean = false;
  allowAssignedUserSearch: boolean = false;
  displayAssignedUserSearch: boolean = false;
  userGroupRead: boolean = false;
  activityLogAccess: boolean = false;

  dueDateOrderBy: "ASC" | "DESC" | null = null;
  userGroupOptions: UserGroupQueryPacket[] = [];

  constructor(
    public matDialog: MatDialog,
    protected snack: FennecSnackbarService,
    private xfwfService: XfwfService,
    private router: Router,
    protected route: ActivatedRoute,
    private keycloakService: KeycloakService,
    protected miCaseService: MICaseService,
    protected userGroupService: UserGroupService
  ) {
    super();
    this.miCaseId = this.route.parent?.snapshot.paramMap.get("miCaseId") ?? null;
    if (this.miCaseId != null) {
      this.view = "MICASE";
    }
    this.formGroup = new FormGroup({
      xfwfListStatus: new FormControl(this.mode),
      xfwfListType: new FormControl("All"),
      miCaseId: new FormControl(""),
      userId: new FormControl("MINE"),
      userIdSearch: new FormControl(""),
      userGroupSearch: new FormControl(-1)
    });
    // If the user can override xfwf tasks, show the override column on the UI.
    const roles = keycloakService.getUserRoles();
    this.admin = roles.includes("ADMIN");
    this.allowOverride = roles.includes("XFWF_TASK_MANUAL_OVERRIDE");
    this.allowUnassignedTaskAssign = roles.includes("XFWF_UNASSIGNED_TASK_ASSIGN");
    this.allowUnassignedTaskRead = roles.includes("XFWF_UNASSIGNED_TASK_READ");
    this.allowAssignedUserSearch = roles.includes("XFWF_ASSIGNED_USER_SEARCH");
    this.userGroupRead = roles.includes("USERGROUP_READ");
    this.activityLogAccess = this.admin || roles.includes("XFWF_ACTIVITY_LOG");

    if (this.activityLogAccess) {
      this.displayedColumns.push("history");
    }
    

    this.miCaseService.refreshChildComponents$.pipe(
      takeUntil(this.destroyed$)
    ).subscribe(_ => {
      if (this.view === "MICASE") {
        this.getMiCaseInfo();
      }
      this.initialized = false;
      this.getTaskList();
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.view === "MICASE") {
        this.getMiCaseInfo();
      }
      this.getTaskList();
    });

    this.formGroup.controls["userId"].valueChanges.subscribe(val => {
      this.displayAssignedUserSearch = val !== "MINE" && (this.allowAssignedUserSearch || this.admin);
      this.getTaskList();
    })

    this.formGroup.controls["xfwfListStatus"].valueChanges.subscribe(val => {
      this.getTaskList();
    })

    this.formGroup.controls["xfwfListType"].valueChanges.subscribe(val => {
      this.getTaskList();
    })

    this.formGroup.controls["userGroupSearch"].valueChanges.subscribe(val => {
      this.getTaskList();
    })

    if (this.paginator) {
      this.paginator.page.subscribe(() => {
        this.getTaskList();
      });
    }

    if (this.miCaseId == null) {
      this.displayedColumns.unshift("id");
    }

    setTimeout (() => {
      this.setShowCreateManualButton();
    }, 50);

  }

  ngOnInit(): void {
    if(this.mode === "ALL") {
      this.formGroup.controls['userId'].setValue(this.mode === "ALL" ? "ALL" : "MINE");
      this.formGroup.controls['xfwfListStatus'].setValue("All");
    }

    this.getUserGroups();
    this.getTaskTypes();
    this.getTaskStatuses();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['caseOnHold'] && !changes['caseOnHold'].isFirstChange()) {
      this.showCreateManualButton = !this.caseOnHold && this.view.indexOf("MICASE") != -1 && (
        this.keycloakService.isUserInRole("XFWF_MANUAL_TASK_ENTRY") || this.admin
      );
      this.getTaskList();
    }
  }

  setShowCreateManualButton() {
    this.showCreateManualButton = 
      this.miCaseId !== null &&
      this.miCaseInfo !== null &&
      this.miCaseInfo !== undefined &&
      !this.miCaseInfo.terminalStatus &&
      (this.keycloakService.isUserInRole("XFWF_MANUAL_TASK_ENTRY") || this.keycloakService.isUserInRole("ADMIN"));
  }

  getUserGroups() {
    if (!this.userGroupRead && !this.admin) {
      super.showWarningSnack("Role USERGROUP_READ is required to populate the User Group filter");
      return;
    }
    this.performXFRequest({
      requestDescription: "Get user groups",
      requestFn: this.userGroupService._list,
      fnParams: [0, 100],
      onSuccess: data => this.userGroupOptions = data,
      onError: err => super.showErrorSnack(err)
    });
  }

  getTaskTypes() {
    this.performXFRequest({
      requestDescription: "Get Task Types",
      requestFn: this.xfwfService.getTaskTypes,
      fnParams: [],
      onSuccess: data => {
        this.taskTypes = data;
      },
      onError: errString => {
        super.showErrorSnack(errString);
      }
    });
  }

  getTaskStatuses() {
    this.performXFRequest({
      requestDescription: "Get Task Statuses",
      requestFn: this.xfwfService.getTaskStatuses,
      fnParams: [],
      onSuccess: data => {
        this.taskStatuses = data;
      },
      onError: errString => {
        super.showErrorSnack(errString);
      }
    });
  }

  getMiCaseInfo() {
    if (this.miCaseId == null || this.miCaseId === "-1") {
      return;
    }
    this.performXFRequest({
      requestDescription: "GET Case Info",
      requestFn: this.miCaseService.getMICaseInfo,
      fnParams: [parseInt(this.miCaseId)],
      onResponse: response => {
        if (response.data != null) {
          this.miCaseInfo = response.data;
          this.setShowCreateManualButton();
        }
      },
      onError: errString => {
        super.showErrorSnack(errString);
      }
    });
  }

  getRowStyle(row: any) {
    if (row.taskStatus === "STARTED") {
      return {
        // "background-color": "#d3f8d3"
        "background-color": "rgba(211, 248, 211, 0.6)"
      }
    } else if (row.taskStatus === "TERMINATED") {
      return {
        // "background-color": "#ffffcc"
        "background-color": "rgba(255, 255, 204, 0.6)"
      }
    } else if (row.taskStatus === "ASSIGNED") {
      return {
        // "background-color": "#e3cfb5"
        "background-color": "rgba(227, 207, 181, 0.6)"
      }
    } else if (row.taskStatus === "COMPLETE") {
      return {
        // "background-color": "#adebad"
        "background-color": "rgba(173, 235, 173, 0.6)"
      }
    } else {
      return {
        // "background-color": "#f1e7da"
        "background-color": "rgba(241, 231, 218, 0.6)"
      }
    }
  }

  getTaskGivenId(id: number): any {
    let task = null;
    task = this.taskList.find((x: any) => x.id === id);
    return task;
  }

  getTaskList() {
    const pageSize = !this.paginator?.pageSize ? this.defaultPageSize : this.paginator.pageSize;
    const first = this.paginator?.pageIndex ? this.paginator.pageIndex * pageSize : 0;
    const type = this.formGroup.get("xfwfListType")?.value;
    const status = this.formGroup.get("xfwfListStatus")?.value;
    const userGroup = this.formGroup.get("userGroupSearch")?.value;

    // Hanldes userid search and my task logic
    let user = null;
    if (this.formGroup.get("userId")?.value === "MINE") {
      user = this.formGroup.get("userId")?.value;
    } else if (this.formGroup.get("userIdSearch")?.value !== "") {
      user = this.formGroup.get("userIdSearch")?.value;
    }

    let miCaseIdValue = this.formGroup.get("miCaseId")?.value;
    let miCaseId = -1;
    if (this.miCaseId) {
      miCaseId = parseInt(this.miCaseId);
    } else if (miCaseIdValue) {
      miCaseId = miCaseIdValue;
    }

    const params = {
      miCaseId: miCaseId,
      status: status,
      type: type,
      userId: user,
      dueDateOrder: this.dueDateOrderBy,
      userGroup: userGroup
    }

    this.performXFRequest({
      requestDescription: "GET task list",
      requestFn: this.xfwfService.getTaskListByParams,
      fnParams: [params, first, pageSize],
      onResponse: (resp: PagedResponse<any>) => {
        this.taskList = resp.data;
        this.totalRowCount = resp.totalRowCount;
        if (!!resp.data && !!resp.data.tasks) {
          this.taskList = resp.data.tasks;
        } else {
          this.taskList = [];
        }
        setTimeout(() => {
          // Note: this is being used to prevent ExpressionChangedAfterChecked errors.
          // Better approach: Build the list of task actions upon receiving incoming task list data, map each task--
          // Adding the actions which are currently built using *ngIf in the HTML. Then we can hide the mat-menu button
          // using a simple calculation of whether OUR list of task actions is greater than zero
          this.initialized = true;
        }, 300);
      },
      onError: errString => {
        super.showErrorSnack(errString);
      }
    });
  }

  onAction(id: number, actionName: string, actionLabel: string) {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";
    let task = this.getTaskGivenId(id);

    matDialogConfig.data = {
      context: [
        {
          label: "Task Id",
          value: task.id
        },
        {
          label: "Stay Id",
          value: task.miCaseId
        },
        {
          label: "Name",
          value: task.miCaseName
        },
        {
          label: "Type",
          value: task.taskType
        }
      ],
      title: "Task Action: " + actionLabel,
      actionName: actionName,
      actionLabel: actionLabel,
      tasks: [task]
    };

    const dialogRef = this.matDialog.open(XFWFActionDialogComponent, matDialogConfig);
    dialogRef.afterOpened().subscribe(() => {
      const component = dialogRef.componentInstance;
      component?.onSubmit$
        .pipe(takeUntil(this.destroyed$))
        .subscribe((user) => {
          this.submitTaskAction(id, actionName, user).then((result) => {
            if (!result) {
              return;
            }
            dialogRef.close();
            this.miCaseService.emitRefreshCaseInfo(); // parent will tell us to refresh
            this.getTaskList();
          });
        });
    });
  }

  submitTaskAction(id: number, actionName: string, selectedUser: SelectedUser | null): Promise<boolean> {
    return new Promise<boolean>((resolve, _reject) => {
      const payload = {
        assignedToUserId: selectedUser?.id
      };
      this.xfwfService.processXFWFTaskAction(id, actionName, payload).subscribe(response => {
        if (response.hasErrors) {
          super.showErrorSnack(response.consolidatedErrorMessage);
          resolve(false);
        } else {
          super.showSuccessSnack(response.data);
          resolve(true);
        }
      });
    });
  }

  onOpenActivityLog(id: any) {
    this.router.navigateByUrl(`/xfwf-activity-log/${id}`);
  }

  onOpenMiCase(id: any) {
    this.router.navigateByUrl(`/mi-case/${id}/task-list`);
  }

  onOpenActivityLogDialog(id: any) {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";

    const aldConfig: ActivityLogDialogConfig = new ActivityLogDialogConfig();
    aldConfig.activityLogEntity = "XFWF_TASK";
    aldConfig.relatedId = parseInt(id);
    matDialogConfig.data = aldConfig;
    const dialogRef = this.matDialog.open(ActivityLogDialogComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.confirm) {
      }
    });
  }

  onManualOverride(id: any) {
    if (!this.allowOverride) {
      return;
    }
    const task = this.getTaskGivenId(id);
    if (task == null) {
      return;
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      question: "Manually Override Task: " + task.taskType +
        " for Case: " + task.miCaseName + "?"
    }

    const dialogRef = this.matDialog.open(SingleChoiceDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if(result?.confirm) {
        this.xfwfService.processXFWFTaskAction(id, "MANUAL_OVERRIDE").subscribe(response => {
          if (response.hasErrors) {
            super.showErrorSnack(response.consolidatedErrorMessage);
          } else {
            this.getTaskList();
          }
        });
      }
    });
  }

  formatDate(dateString: string) {
    return DateUtil.getDisplayDate(dateString);
  }

  createManualTask() {
    if (this.miCaseId == null || this.taskTypes == null) {
      super.showErrorSnack("Data required for manual task creation is not present. Please contact system support.")
      return;
    }
    // show create task dialog
    const config = new MatDialogConfig();
    config.data = {
      miCaseId: this.miCaseId,
      taskTypes: this.getManualEntryTaskTypes(),
      taskStatuses: this.taskStatuses
    };

    const dialog = this.matDialog.open(XfwfCreateManualComponent, config);
    dialog.afterClosed().subscribe((data) => {
      this.initialized = false;
      if (data?.manualTaskCreated) {
        this.miCaseService.emitRefreshCaseInfo(); // parent will tell us to refresh
        this.getTaskList();
      }
    });
  }

  getManualEntryTaskTypes(): any[] {
    if (this.taskTypes == null) {
      return [];
    }
    return this.taskTypes.filter((type: any) => type.properties.allowManualEntry === true);
  }

  openCommentDialog = (xfwfTaskId: number) => {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "75vw";
    matDialogConfig.data = {
      mode: "XFWF_TASK",
      relatedId: xfwfTaskId
    }
    const dialogRef = this.matDialog.open(CommentListDialogComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe((data) => {
      this.getTaskList(); // refresh
    });
  }

  openHoldInfoForMICase(t: SimpleObject) {
    if (this.view == 'MICASE') {
      this.editHoldInfo.emit();
      return;
    }

    if (t == null || t["miCaseId"] == null) {
      // We shouldn't hit this but it's just to catch any technical errors
      super.showErrorSnack("There was an error with navigating to the Stay, please click the Stay ID link instead");
      return;
    }

    this.router.navigateByUrl(`/mi-case/${t["miCaseId"]}`, { state: { "HOLD_INFO_DIALOG": true } });
  }

  getDisplayString = (input:number) => {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    });
    return formatter.format(input);
  }

  toggleDueDateOrderBy = () => {
    if(this.dueDateOrderBy === "ASC" || this.dueDateOrderBy == null) {
      this.dueDateOrderBy = "DESC";
    }else if(this.dueDateOrderBy === "DESC") {
      this.dueDateOrderBy = "ASC"
    }
    this.getTaskList();
  }
}
